import * as React from 'react';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { graphql, navigate } from 'gatsby';
import ContentWithBody from '../components/contentWithBody/contentWithBody';
import Header from '../common/header/header';
import Footer from '../common/footer/footer';
import BackToTopScroll from '../components/backToTopScroll/backToTopScroll';
import GlobalStyles from '../styles/globalStyles';
import { Body404 } from '../styles';

const NotFoundPage = ({ data, location }) => {
  const languageFilteredData = data?.allContentfulSparlings?.edges?.find(
    (edge) => edge?.node?.node_locale === 'en-US'
  );
  React.useEffect(() => {
    if (location?.href !== location?.href?.toLowerCase()) {
      window.location.href = location?.href?.toLowerCase();
    }
  }, []);
  return (
    <>
      <GlobalStyles />
      <Header />
      <Body404>
        <ContentWithBody
          sectionData={languageFilteredData?.node?.sections.find(
            (section) => section?.contentDetails
          )}
        />
      </Body404>

      <Footer />
      <BackToTopScroll />
    </>
  );
};

export default NotFoundPage;
export const pageQuery = graphql`
  query NotFoundPageQuery {
    allContentfulSparlings(
      filter: { identifier: { type: { eq: "notFound" } } }
    ) {
      edges {
        node {
          node_locale
          title
          slug
          contentModels
          sections {
            ... on ContentfulContent {
              id
              title
              type
              header
              contentDetails {
                raw
              }
            }
          }
        }
      }
    }
  }
`;
